import React from 'react';
import "./styles.css";
import useServiceUsers from "../../hooks/useServiceUsers";
import {useRecoilValue} from "recoil";
import totalSignedUsersSelector from "../../recoil/selectors/totalSignedUsersSelector";
import Header from "../../components/layout/header/Header";
import 'moment/locale/pt-br'
import LastSignedUserContainer from "../../components/last-signed-user-container/LastSignedUserContainer";
import lastSignedUserSelector from "../../recoil/selectors/lastSignedUserSelector";
import usersCountTextSelector from "../../recoil/selectors/usersCountTextSelector";
import moment from "moment";


function MainLayout() {
  const { data: users } = useServiceUsers();
  const totalSignedUsers = useRecoilValue(totalSignedUsersSelector);
  const lastSignedUser = useRecoilValue(lastSignedUserSelector);
  const usersCountText = useRecoilValue(usersCountTextSelector);
  const date = moment().format('DD [de] MMMM, YYYY');

  return (
    <div className="main-layout-container left-display">
      <Header/>
      
      <main>
        {lastSignedUser !== null ? (
          <LastSignedUserContainer
            data={lastSignedUser}
          />
        ) : (
          <div className="users-list-container">
            {totalSignedUsers === 0 && (
              <div className="text-white text-center text-4xl font-bold mb-3 mt-5 z-10">
                <h2 className="text-white">
                  {usersCountText}
                  <span className="animate-bounce  text-white h-3 inline-block">.</span>
                  <span className="animate-bounce delay-75 text-white h-5 inline-block">.</span>
                  <span className="animate-bounce delay-300 text-white h-3 inline-block">.</span>
                </h2>
              </div>
            )}
          </div>
        )}
      </main>
    </div>
  );
}

export default MainLayout;