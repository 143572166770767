import {selector} from "recoil";
import signedUsersSelector from "./signedUsersSelector";
import {User} from "../../hooks/useServiceUsers";

const lastSignedUserSelector = selector<User | null>({
  key: 'lastSignedUserSelector', // unique ID (with respect to other atoms/selectors)
  get: ({get}) => {
    const users = get(signedUsersSelector);
    return users[0] || null;
  },
});

export default lastSignedUserSelector

