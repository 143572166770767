import React from "react";
import MainLayout from "./components/layout/main-layout/MainLayout";
import fetcher from "./utils/fetcher";
import {SWRConfig} from "swr";
import {useRecoilValue} from "recoil";
import appConfigState from "./recoil/atoms/appConfigState";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import Esquerda from "./pages/esquerda/Esquerda";
import Direita from "./pages/direita/Direita";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout/>,
  },
  {
    path: "/esquerda",
    element: <Esquerda/>,
  },
  {
    path: "/direita",
    element: <Direita/>,
  },
]);

export default function App() {
  const appConfig = useRecoilValue(appConfigState);
  const {refreshInterval} = appConfig;
  
  return (
    <SWRConfig
      value={{
        refreshInterval,
        fetcher
      }}
    >
      <div className="w-full h-full">
        <RouterProvider router={router} />
      </div>
    </SWRConfig>
  )
}
