import {selector} from "recoil";
import usersState from "../atoms/usersState";

const signedUsersSelector = selector({
  key: 'signedUsersSelector', // unique ID (with respect to other atoms/selectors)
  get: ({get}) => {
    const users = get(usersState);
    return users.filter(user => user.inEvent).sort((a, b) => {
      return b.entryTime.localeCompare(a.entryTime);
    });
  },
});

export default signedUsersSelector

