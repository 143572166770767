import React from 'react';
import {User} from "../hooks/useServiceUsers";
import UserProfilePicture from "./UserProfilePicture";

interface Props {
  data: User
}
function UserInfo(props: Props) {
  return (
    <>
      <div className="flex gap-x-4">
        <UserProfilePicture
          src={props.data.photoUrl}
          alt={props.data.user}
        />
        
        <div className="min-w-0 flex items-center justify-center">
          <p className="user-name text-sm font-semibold leading-6 text-white">
            <span className="absolute inset-x-0 -top-px bottom-0" />
            {props.data.user}
          </p>
        </div>
      </div>
      
      <div className="user-log-info flex items-center gap-x-4">
        <div className="hidden sm:flex sm:flex-col sm:items-end">
          <p className="user-log-info-group text-sm leading-6 text-white">
            {props.data.group}
          </p>
          
          {props.data.entryTime ? (
            <p className="user-log-time mt-1 text-xs leading-5 text-white">
              Chegada <time dateTime={props.data.entryTime}>{props.data.entryTime}</time>
            </p>
          ) : (
            <div className="mt-1 flex items-center gap-x-1.5">
              <div className="flex-none rounded-full bg-red-500/20 p-1">
                <div className="h-1.5 w-1.5 rounded-full bg-red-500" />
              </div>
              <p className="text-xs leading-5 text-white">Offline</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default UserInfo;