import useSWR from "swr";
import {useEffect} from "react";
import {useRecoilState} from "recoil";
import usersState from "../recoil/atoms/usersState";

export interface User {
  user: string
  photoUrl: string
  group: string
  inEvent: boolean
  entryTime: string
}

const {
  REACT_APP_API_URL = 'https://api.mem.seb.leandrodasilva.dev'
} = process.env;

export default function useServiceUsers() {
  const { data, error, isLoading } = useSWR<{users: User[]}>(REACT_APP_API_URL);
  const [users, setUsers] = useRecoilState(usersState);
  
  useEffect(() => {
    if (data) {
      setUsers(data?.users);
    }
  }, [data, setUsers])
  
  return {
    data: users,
    error,
    isLoading
  }
}