import {selector} from "recoil";
import signedUsersSelector from "./signedUsersSelector";

const usersCountTextSelector = selector<string>({
  key: 'usersCountTextSelector', // unique ID (with respect to other atoms/selectors)
  get: ({get}) => {
    const signedUsers = get(signedUsersSelector);
    if (signedUsers.length === 0) {
      return "Aguardando participantes";
    } else if (signedUsers.length === 1) {
      return "participante";
    }
    return "participantes";
  },
});

export default usersCountTextSelector

