import React, {useState} from 'react';
import Card from "../card/Card";
import UserProfilePicture from "../UserProfilePicture";
import {User} from "../../hooks/useServiceUsers";
import classNames from "../../utils/classNames";
import "./styles.css";

interface Props {
  data: User | null
}

function LastSignedUserContainer(props: Props) {
  const [user, setUser] = useState<User | null>(null);
  const [byeUser, setByeUser] = useState<User | null>(null);
  const data = byeUser || user;
  
  React.useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    
    if (user && props.data && props.data.entryTime.localeCompare(user.entryTime) > 0) {
      setByeUser(user);
      setUser(null);
    }
    
    timeout = setTimeout(() => {
      if (props.data !== null) {
        setUser(props.data);
        setByeUser(null);
      }
    }, 2000)
    
    return () => {
      if (timeout !== null) clearTimeout(timeout);
    }
  }, [props.data, user])
  
  if (!data) return null;
  
  return (
    <div className={classNames(
      "user-last-signed-container",
      byeUser ? "disappear" : "appear"
    )}>
      <p className="signin-greetings">Olha só quem chegou!!!</p>
      <Card>
        <UserProfilePicture
          src={data?.photoUrl || ""}
          alt={data?.user || "user"}
        />
        
        <p className="user-name">{data?.user || "user"}</p>
      </Card>
    </div>
  );
}

export default LastSignedUserContainer;