import {selector} from "recoil";
import usersState from "../atoms/usersState";
import signedUsersSelector from "./signedUsersSelector";

const totalSignedUsersSelector = selector({
  key: 'totalSignedUsersSelector', // unique ID (with respect to other atoms/selectors)
  get: ({get}) => {
    const users = get(signedUsersSelector);
    return users.length;
  },
});

export default totalSignedUsersSelector

