import React from 'react';
import "./styles.css";
import AppLogoIcon from "../../icons/AppLogoIcon";
import SebLogoIcon from "../../icons/SebLogoIcon";
import moment from "moment/moment";

function Header() {
  const date = moment().format('DD [de] MMMM, YYYY');
  
  return (
    <header className="header-container bg-primary">
      <div className="mx-auto flex h-full items-center justify-between">
        <a href="/" className="mem-logo-link">
          <AppLogoIcon/>
        </a>
        
        <h1 className="text-white uppercase font-bold text-2xl">
          Painel de ocupação do evento
        </h1>
        
        <div className="flex items-center gap-x-8">
          <a
            href="https://www.seb.com.br"
            target="_blank"
            className="seb-logo-link"
            rel="noreferrer"
          >
            <SebLogoIcon/>
          </a>
        </div>
      </div>
      
      <time className="time-badge">
        {date}
      </time>
    </header>
  );
}

export default Header;