import React from 'react';
import classNames from "../utils/classNames";

interface Props {
  src: string
  alt: string
  online?: boolean
}
function UserProfilePicture(props: Props) {
  return (
    <img
      className={classNames(
        "user-profile-picture h-12 w-12 flex-none rounded-full border-4 object-cover bg-white border-white",
      )}
      src={props.src}
      alt={props.alt}
      loading="lazy"
    />
  );
}

export default UserProfilePicture;