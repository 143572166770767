import React, {ReactNode} from 'react';
import "./styles.css";

const Card: React.FC<{children: ReactNode}> = ({children}) => {
  return (
    <div className="card-container glass">
      {children}
    </div>
  );
}

export default Card;