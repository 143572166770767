import React from 'react';
import "./styles.css";
import {useRecoilValue} from "recoil";
import signedUsersSelector from "../../recoil/selectors/signedUsersSelector";
import UserInfo from "../UserInfo";
import totalSignedUsersSelector from "../../recoil/selectors/totalSignedUsersSelector";
import usersCountTextSelector from "../../recoil/selectors/usersCountTextSelector";
import CountUp from "react-countup";

function UserLogList() {
  const users = useRecoilValue(signedUsersSelector);
  const totalSignedUsers = useRecoilValue(totalSignedUsersSelector);
  const usersCountText = useRecoilValue(usersCountTextSelector);
  
  return (
    <ul
      className="user-log-list-container glass"
    >
      <li>
        <div className="text-white text-center text-4xl font-bold mb-3 mt-5 z-10">
          <h2>
            {totalSignedUsers > 0 && (
              <>
                <CountUp
                  end={totalSignedUsers}
                  duration={5}
                />
                {" "}
              </>
            )}
            {usersCountText}
          </h2>
        </div>
      </li>
      {users.map((user, i) => (
        <li key={user.user + i} className="relative flex justify-between gap-x-6 px-4 py-5 hover:opacity-100 sm:px-6">
          <UserInfo
            data={user}
          />
        </li>
      ))}
    </ul>
  );
}

export default UserLogList;