import React from 'react';
import "./styles.css";
import UserLogList from "../../user-log-list/UserLogList";
import useServiceUsers from "../../../hooks/useServiceUsers";
import {useRecoilValue} from "recoil";
import totalSignedUsersSelector from "../../../recoil/selectors/totalSignedUsersSelector";
import Header from "../header/Header";
import 'moment/locale/pt-br'
import LastSignedUserContainer from "../../last-signed-user-container/LastSignedUserContainer";
import lastSignedUserSelector from "../../../recoil/selectors/lastSignedUserSelector";
import usersCountTextSelector from "../../../recoil/selectors/usersCountTextSelector";
import moment from "moment";
import SebLogoIcon from "../../icons/SebLogoIcon";
import Card from "../../card/Card";


function MainLayout() {
  const { data: users } = useServiceUsers();
  const totalSignedUsers = useRecoilValue(totalSignedUsersSelector);
  const lastSignedUser = useRecoilValue(lastSignedUserSelector);
  const usersCountText = useRecoilValue(usersCountTextSelector);
  const date = moment().format('DD [de] MMMM, YYYY');

  return (
    <div className="main-layout-container">
      <Header/>
      
      <main>
        {lastSignedUser !== null && (
          <LastSignedUserContainer
            data={lastSignedUser}
          />
        )}
        
        <div className="users-list-container">
          {totalSignedUsers === 0 && (
            <div className="text-white text-center text-4xl font-bold mb-3 mt-5 z-10">
              <h2 className="text-white">
                {usersCountText}
                <span className="animate-bounce  text-white h-3 inline-block">.</span>
                <span className="animate-bounce delay-75 text-white h-5 inline-block">.</span>
                <span className="animate-bounce delay-300 text-white h-3 inline-block">.</span>
              </h2>
            </div>
          )}
          
          {totalSignedUsers > 0 && (
            <UserLogList/>
          )}
        </div>

        <div className="seb-time-container">
          <Card>
            <a
              href="https://www.seb.com.br"
              target="_blank"
              className="seb-logo-link"
              rel="noreferrer"
            >
              <SebLogoIcon/>
            </a>

            <time className="time-badge">
              {date}
            </time>
          </Card>
        </div>
      </main>
    </div>
  );
}

export default MainLayout;